import moment from 'moment'
import { useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import Loading from '../../components/Common/Loading/Loading'
import useBoardHook from '../../hook/dashboard/useBoardHook'
import { TypeOfConditionnement } from '../../models'
import ResearchBarDashboard from '../stock/components/ResearchBar/ResearchBarDashboard'
import { ModalName } from '../stock/modal/customModalSlice'
import Board from './component/Board'
import './index.scss'
import { useTranslation } from 'react-i18next'
import Define from '../../constants/define'
import { Empty } from 'antd'

const Dashboard = () => {
  const {
    boardStyle,
    pinBoard = () => {},
    onClickTitle = () => {},
    stockBoardList,
    attenduBoardList,
    commandeBoardList,
    stockPie,
    commandePie,
    stockStick,
    attenduStick,
    commandeStick,
    isLoading,
    searchDataDashboard,
  } = useBoardHook()
  const { currentPage } = useSelector((state: RootState) => state.page)
  const { t } = useTranslation()
  const boardInfoAttendu =
    boardStyle.find((el) => el.type === ModalName.ATTENDU) || {}
  const boardInfoCommande =
    boardStyle.find((el) => el.type === ModalName.COMMANDE) || {}
  const boardInfoStock =
    boardStyle.find((el) => el.type === ModalName.STOCK) || {}

  const pieChartAttenduColors = ['#20458F', '#D5EAF7']
  const pieChartCommandeColors = ['#DDC2FF', '#F1E9FB', '#AB6BFA']
  const pieChartStockColors = ['#98ECD7', '#C1FFF0']
  // const choosingClient = localStorage.getItem(
  //   Define.CHOOSING_CLIENT_CODENOM_MULTIPLE
  // )
  // const choosingCompany = localStorage.getItem(
  //   Define.CHOOSING_COMPANY_CODENOM_MULTIPLE
  // )
  // const choosingWarehouse = localStorage.getItem(
  //   Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE
  // )
  const choosingClient = localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM)
  const choosingCompany = localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
  const choosingWarehouse = localStorage.getItem(
    Define.CHOOSING_WAREHOUSE_CODENOM
  )

  const onClickPin = (type: ModalName) => {
    pinBoard(type)
  }

  const fakeDataPieChart: TypeOfConditionnement[] = [
    {
      level: 'litiges',
      percent: '0',
    },
    {
      level: 'san_litiges',
      percent: '100',
    },
  ]

  return (
    <>
      <ResearchBarDashboard
        requiredToAdd={['client', 'company', 'warehouse']}
        onSearch={searchDataDashboard}
      />
      <div className="flex justify-end italic text-[#524e4e] text-sm mt-1 mr-12">
        Mise à jour : {moment().format('DD/MM/YY - HH:mm')}
      </div>
      <div className="px-10 flex justify-between">
        <div className="page-title-heading-1">{currentPage}</div>
        <div className="rounded-md h-10 w-11 flex items-center justify-center p-0.5">
          <i className="icon-import w-full h-full"></i>
        </div>
      </div>
      <div className="px-10 my-2 dashboard">
        {isLoading ? (
          <Loading />
        ) : !choosingClient || !choosingWarehouse || !choosingCompany ? (
          <span>
            <Empty description={''} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            <p className="text-center">{t('notEnoughCondition')}</p>
          </span>
        ) : (
          <>
            <Board
              type={ModalName.ATTENDU}
              onClickPin={() => onClickPin(ModalName.ATTENDU)}
              boardInfo={boardInfoAttendu}
              initData={attenduBoardList}
              dataPieChart={fakeDataPieChart}
              colorsPieChart={pieChartAttenduColors}
              dataBarChart={attenduStick}
              onClickTitle={onClickTitle}
            />
            <Board
              type={ModalName.STOCK}
              onClickPin={() => onClickPin(ModalName.STOCK)}
              boardInfo={boardInfoStock}
              initData={stockBoardList}
              dataPieChart={stockPie}
              colorsPieChart={pieChartStockColors}
              dataBarChart={stockStick}
              onClickTitle={onClickTitle}
            />
            <Board
              type={ModalName.COMMANDE}
              onClickPin={() => onClickPin(ModalName.COMMANDE)}
              boardInfo={boardInfoCommande}
              initData={commandeBoardList}
              colorsPieChart={pieChartCommandeColors}
              dataPieChart={commandePie}
              dataBarChart={commandeStick}
              onClickTitle={onClickTitle}
            />
          </>
        )}
      </div>
    </>
  )
}

export default Dashboard
