import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import { ModalName } from '../../stock/modal/customModalSlice'
import '../index.scss'
import { useTranslation } from 'react-i18next'
import { mapDataBarChart, mapDateLabelsBarChart } from '../func/mapDataBoard'
import { StickChartItem } from '../../../models'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarChartInterface {
  initData: StickChartItem[]
  colors?: string[]
  type?: ModalName
  isPinned?: boolean
}

const BarChart = (props: BarChartInterface) => {
  const { type, isPinned, initData = [] } = props
  const { t } = useTranslation()
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        titleFont: {
          size: isPinned ? 12 : 10,
        },
        bodyFont: {
          size: isPinned ? 12 : 10,
        },
        callbacks: {
          label: (context: any) => {
            const label = context.dataset.label || ''
            const value = context.parsed.y || 0
            return `${label}: ${value}`
          },
          title: (context: any) => {
            const index = context[0].dataIndex
            return `${mapDateLabelsBarChart(initData).uniqueDateMonth[index]}`
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          offset: false,

          display: false,
        },
        border: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          offset: false,
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          min: 0,
          max: 40,
          stepSize: 10,
        },
      },
    },
  }

  const labels = mapDateLabelsBarChart(initData).uniqueDates

  const attenduLegend = [
    {
      name: t('dashboard.on_hold'),
      bgColor: 'bg-[#20458F]',
    },
    {
      name: t('dashboard.receipts'),
      bgColor: 'bg-[#D5EAF7]',
    },
  ]
  const stockLegend = [
    {
      name: t('dashboard.unavailable'),
      bgColor: 'bg-[#98ECD7]',
    },
    { name: t('dashboard.available'), bgColor: 'bg-[#C1FFF0]' },
  ]
  const commandeLegend = [
    {
      name: t('dashboard.on_hold'),
      bgColor: 'bg-[#DDC2FF]',
    },
    { name: t('dashboard.prepared'), bgColor: 'bg-[#F1E9FB]' },
    { name: t('dashboard.loaded'), bgColor: 'bg-[#AB6BFA]' },
  ]

  const borderRadius = 50
  const borderRadiusAllCorners = {
    topLeft: borderRadius,
    topRight: borderRadius,
    bottomLeft: borderRadius,
    bottomRight: borderRadius,
  }

  const datasetMapToBarChart = mapDataBarChart({
    data: initData,
    chartType: type,
  }).map((item: any) => ({
    ...item,
    barThickness: 6,
    borderRadius: borderRadiusAllCorners,
    borderSkipped: false,
  }))

  const data = {
    labels,
    datasets: datasetMapToBarChart,
  }

  const pinnedBoardWidth = `${
    data.labels.length * (isPinned && data.labels.length <= 2 ? 20 : 16)
  }%`

  return (
    <div className={`h-1/2 flex flex-row ${isPinned && 'pl-5 mr-8'}`}>
      <div
        className={`${isPinned ? 'ml-6' : 'ml-2'} ${
          data.labels.length <= 2 ? 'pr-8' : ''
        }`}
        style={{ width: pinnedBoardWidth }}
      >
        <Bar options={options} data={data} width="100%" />
      </div>
      <div className={`flex flex-col justify-center w-auto overflow-x-hidden`}>
        {type === ModalName.ATTENDU
          ? attenduLegend.map((el, index) => (
              <BarChartLegend
                key={index}
                name={el.name}
                bgColor={el.bgColor}
                isPinned={isPinned}
              />
            ))
          : type === ModalName.STOCK
          ? stockLegend.map((el, index) => (
              <BarChartLegend
                key={index}
                name={el.name}
                bgColor={el.bgColor}
                isPinned={isPinned}
              />
            ))
          : commandeLegend.map((el, index) => (
              <BarChartLegend
                key={index}
                name={el.name}
                bgColor={el.bgColor}
                isPinned={isPinned}
              />
            ))}
      </div>
    </div>
  )
}

const BarChartLegend = (props: {
  name: string
  bgColor: string
  isPinned?: boolean
}) => {
  return (
    <div className={`flex items-center ${props.isPinned ? 'pb-2' : 'pb-1'}`}>
      <div
        className={`${props.bgColor} ${
          props.isPinned ? 'w-6 h-3 text-sm' : 'w-4 h-2 text-xs'
        } rounded mr-2`}
      ></div>
      <div
        className={`${props.isPinned ? 'text-sm' : 'text-xs'} text-[#7E7E7E]`}
      >
        {props.name}
      </div>
    </div>
  )
}

export default BarChart
