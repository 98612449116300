import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js'
import { Pie } from 'react-chartjs-2'
import { TypeOfConditionnement } from '../../../models/Dashboard'
import { ModalName } from '../../stock/modal/customModalSlice'
import { mapLevelPieChart } from '../func/mapDataBoard'

ChartJS.register(ArcElement, Tooltip, Legend)

interface PieChartInterface {
  initData: TypeOfConditionnement[]
  colors: string[]
  isPinned?: boolean
  chartType?: ModalName
}

const PieChart = (props: PieChartInterface) => {
  const {
    initData,
    colors = [],
    isPinned,
    chartType = ModalName.ATTENDU,
  } = props

  const data = {
    labels: initData.map((el) =>
      mapLevelPieChart({
        level: el.level,
        chartType,
      })
    ),
    datasets: [
      {
        label: '',
        data: initData.map((el) => el.percent),
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        position: 'right' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'rectRounded',
          pointStyleWidth: isPinned ? 35 : 22,
          color: '#7E7E7E',
          font: {
            size: isPinned ? 14 : 12,
          },
        },
        onClick: function () {},
      },
    },
    maintainAspectRatio: false,
  }

  return (
    <div className={`h-2/5 w-full`}>
      <Pie data={data} options={options} />
    </div>
  )
}

export default PieChart
