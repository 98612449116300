import React from 'react'
import PIN from '../../../assets/images/btn--import CSV (1).jpg'
import CURRENT_PIN from '../../../assets/images/btn--import CSV.jpg'
import { BoardInfoInterface } from '../../../hook/dashboard/useBoardHook'
import {
  AttenduList,
  CommandeList,
  StickChartItem,
  StockList,
  TypeOfConditionnement,
} from '../../../models/Dashboard'
import { ModalName } from '../../stock/modal/customModalSlice'
import '../index.scss'
import BarChart from './BarChart'
import BoardList from './BoardList'
import PieChart from './PieChart'

interface BoardInterface {
  initData?: AttenduList | StockList | CommandeList
  type: ModalName
  onClickPin: (type: ModalName) => void
  boardInfo: BoardInfoInterface
  dataPieChart?: TypeOfConditionnement[]
  dataBarChart?: StickChartItem[]
  colorsPieChart?: string[]
  isDisplayContent?: boolean
  onClickTitle: (type: ModalName) => void
}

const Board = (props: BoardInterface) => {
  const {
    type,
    onClickPin,
    boardInfo,
    initData = {},
    dataPieChart = [],
    dataBarChart = [],
    colorsPieChart = [],
    onClickTitle = () => {},
  } = props

  return (
    <React.Fragment>
      <div className={boardInfo.className}>
        <div
          className={`rounded-xl bg-[#ffffff14] h-full cursor-pointer ${
            boardInfo.type === ModalName.ATTENDU
              ? 'attendu-board'
              : boardInfo.type === ModalName.COMMANDE
              ? 'commande-board'
              : 'stock-board'
          }`}
          onClick={() => onClickTitle(type)}
        >
          <div className="py-2 px-4">
            <div className="flex justify-between items-center">
              <div className="page-title-heading-1 ml-1">
                {type !== ModalName.STOCK ? `${type}S` : type}
              </div>
              {boardInfo.isPinned ? (
                <img
                  src={CURRENT_PIN}
                  alt="current pin"
                  className="cursor-not-allowed"
                  onClick={(e) => e.stopPropagation()}
                />
              ) : (
                <img
                  src={PIN}
                  alt="pin"
                  className="cursor-pointer"
                  onClick={(e) => {
                    onClickPin(type)
                    e.stopPropagation()
                  }}
                />
              )}
            </div>
            <div className="flex justify-between">
              <BoardList initData={initData} isPinned={boardInfo.isPinned} />
              <div className="w-1/2 flex flex-col justify-between">
                <PieChart
                  initData={dataPieChart}
                  colors={colorsPieChart}
                  isPinned={boardInfo.isPinned}
                  chartType={type}
                />
                <BarChart
                  type={type}
                  isPinned={boardInfo.isPinned}
                  initData={dataBarChart}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Board
