import Define from '../../../constants/define'
import {
  AttenduDashboard,
  AttenduList,
  CommandeDashboard,
  CommandeList,
  StickChartItem,
  StockDashboard,
  StockList,
} from '../../../models'
import { formatDecimal } from '../../../utils'
import { ModalName } from '../../stock/modal/customModalSlice'

export const mapDataStockBoardList = (data: StockDashboard): StockList => {
  return {
    referecence: data.referecence || '0',
    items: data.items || '0',
    items_cdn: data.items_cdn || '0',
    blocked_items: data.blocked_items || '0',
    reserved_items: data.reserved_items || '0',
    movements_inprogress_processed: `${data.movements_inprogress || 0}/${
      data.movements_processed || 0
    }`,
    regularizations_in_progress_processed: `${
      data.regularizations_inprogress || 0
    }/${data.regularizations_processed || 0}`,
    affected_staff: data.affected_staff || '0',
  }
}

export const mapDataAttenduBoardList = (
  data: AttenduDashboard
): AttenduList => {
  return {
    total: data.total || '0',
    items: data.items || '0',
    items_cdn: data.items_cdn || '0',
    referecence: data.referecence || '0',
    disputes: data.disputes || '0',
    reception_inprogress_processed: `${data.reception_inprogress || 0}/${
      data.reception_processed || 0
    }`,
    average_reception_time: `${
      data.average_reception_time
        ? formatDecimal(data.average_reception_time, 4)
        : 0
    } min`,
    placements_in_stock_inprogress_processed: `${
      data.placements_in_stock_inprogress || 0
    }/${data.placements_in_stock_processed || 0}`,
    average_time_to_stock: `${
      data.average_time_to_stock
        ? formatDecimal(data.average_time_to_stock, 4)
        : 0
    } min`,
    affected_staff: data.affected_staff || '0',
  }
}

export const mapDataCommandeBoardList = (
  data: CommandeDashboard
): CommandeList => {
  return {
    total: data.total || '0',
    items: data.items || '0',
    items_cdn: data.items_cdn || '0',
    referecence: data.referecence || '0',
    preparation_in_progress_processed: `${data.load_item || 0}/${
      data.traited_item || 0
    }`,
    average_preparation_time: `${
      data.average_preparation_time
        ? formatDecimal(data.average_preparation_time, 4)
        : 0
    } min`,
    chargement_in_progress_processed: `${data.loading_now || 0}/${
      data.loading_finish || 0
    }`,
    average_loading_time: `${
      data.average_loading_time
        ? formatDecimal(data.average_loading_time, 4)
        : 0
    } min`,
    affected_staff: data.affected_staff || '0',
  }
}

export const mapLevelPieChart = (props: {
  level: string
  chartType: ModalName
}) => {
  const { level, chartType } = props
  switch (chartType) {
    case ModalName.STOCK:
      if (level === Define.MAX_CDN_LEVEL.toString()) return 'Palette'
      else if (level === (Define.MAX_CDN_LEVEL - 1).toString()) return 'Carton'
      break
    case ModalName.COMMANDE:
      if (level === Define.MAX_CDN_LEVEL.toString()) return 'Palette'
      else if (level === (Define.MAX_CDN_LEVEL - 1).toString()) return 'Carton'
      else return 'Multiple'
    case ModalName.ATTENDU:
      if (level === 'litiges') return 'Avec litige(s)'
      else return 'Sans litige'
    default:
      break
  }
}

//map name from api to corresponding color and label
export const mapLabelsAndColorsBarChart = (payload: {
  label: string
  chartType: ModalName
}) => {
  const { label, chartType = ModalName.STOCK } = payload
  switch (label) {
    case 'ON_HOLD':
      return {
        color: chartType === ModalName.ATTENDU ? '#20458F' : '#DDC2FF',
        label: 'En attente',
      }
    case 'RECEIPT':
      return { color: '#D5EAF7', label: 'Reçus' }
    case 'AVAILABLE':
      return {
        color: '#C1FFF0',
        label: 'Disponible',
      }
    case 'UNAVAILABLE':
      return {
        color: '#98ECD7',
        label: 'Indisponible',
      }
    case 'PREPARED':
      return {
        color: '#AB6BFA',
        label: 'Préparés',
      }

    case 'LOADED':
      return {
        color: '#F1E9FB',
        label: 'Chargés',
      }

    default:
      return {
        color: '',
        label: '',
      }
  }
}

//map value of the same name and push to an array (data field)
export const mapDataBarChart = (payload: {
  data: StickChartItem[]
  chartType?: ModalName
}) => {
  const { data = [], chartType = ModalName.STOCK } = payload
  return Object.values(
    data.reduce((acc, { name, value }) => {
      acc[name] = acc[name] || {
        label: mapLabelsAndColorsBarChart({
          label: name,
          chartType,
        }).label,
        data: mapDateLabelsBarChart(data).initData,
        backgroundColor: mapLabelsAndColorsBarChart({
          label: name,
          chartType,
        }).color,
      }
      if (value !== undefined) {
        acc[name].data[acc[name].data.length - 1] = value
      }
      return acc
    }, {} as Record<string, { label: string; data: number[]; backgroundColor: string }>)
  )
}

export const mapDateLabelsBarChart = (data: StickChartItem[]) => {
  const uniqueDateMonth = [...new Set(data.map((item) => item.date))]
  const uniqueDates = [...new Set(data.map((item) => item.date.split('/')[0]))]

  // Sort dates in ascending order
  uniqueDates.sort((a, b) => parseInt(a) - parseInt(b))
  uniqueDateMonth.sort((a, b) => parseInt(a) - parseInt(b))
  // Initialize data array with zeros
  const initData = Array(uniqueDates.length).fill(0)

  return { uniqueDateMonth, uniqueDates, initData }
}
