import { Form, notification, Select } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../app/store'
import CLOSE_ICON from '../../../../assets/icons/close.svg'
import SEARCH_ICON from '../../../../assets/icons/search.svg'
import CDatePicker from '../../../../components/Common/CDatePicker'
import CustomDropdown from '../../../../components/Common/CustomDropdown/CustomDropdown'
import Define from '../../../../constants/define'
import clientWarehouseApi from '../../../../http/clientWarehouseApi'
import dashboardApi from '../../../../http/dashboardApi'
import {
  chooseClient,
  clearClient,
  getClients,
  getCompanies,
  getWarehouses,
} from '../../../../redux/reducers/accountSlice'
import { getUrlFilterParams, isValidSecond } from '../../../../utils'
import './ResearchBar.scss'
import { FilterType } from './ResearchBarExtend'

const ResearchBarDashboard: FC<{
  requiredToAdd?: FilterType[]
  requiredToFilters?: FilterType[]
  onSearch?: (values: any) => void
}> = ({ requiredToAdd = [], onSearch, requiredToFilters }) => {
  const [form] = Form.useForm()
  const [isOnlyOneOption, setOnlyOneOption] = useState(false)
  const [membersOptions, setMemberOptions] = useState<
    { label: string; value: string }[]
  >([])
  const [refOptions, setRefOptions] = useState<
    { label: string; value: string }[]
  >([])

  const filterKey = useSelector((state: RootState) =>
    state.selector && state.selector.data ? state.selector.data.searchBar : null
  )
  const [startDate, setStartDate] = useState<Dayjs>()
  const [endDate, setEndDate] = useState<Dayjs>()

  const {
    loadingClient,
    loadingCompany,
    loadingWarehouse,
    choosingClient,
    choosingCompany,
    choosingWarehouse,
    clientList,
    companyList,
    warehouseList,
  } = useSelector((state: RootState) => state.account)
  const dispatch = useDispatch()

  function getNoneFilterData() {
    // fetch data when there is no dropdown chose
    const filters = {
      user_id: localStorage.getItem(Define.USER_ID) || '',
      user_role: localStorage.getItem(Define.ROLE) || '',
    }
    dispatch(getClients(filters))
    dispatch(getCompanies(filters))
    dispatch(getWarehouses(filters))
  }

  //fetch data first time with multiple select
  // useEffect(() => {
  //   // fetch data when first time mount
  //   const localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
  //   const localClientCodenom =
  //     localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  //   const localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
  //   const localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
  //   const localCompanyNom =
  //     localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
  //   const localWarehouseNom =
  //     localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
  //   const localMutipleClientCodeNom = localStorage.getItem(
  //     Define.CHOOSING_CLIENT_CODENOM_MULTIPLE
  //   )
  //   const localMutipleCompanyCodeNom = localStorage.getItem(
  //     Define.CHOOSING_COMPANY_CODENOM_MULTIPLE
  //   )
  //   const localMutipleWarehouseCodeNom = localStorage.getItem(
  //     Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE
  //   )

  //   if (
  //     !localMutipleClientCodeNom ||
  //     !localMutipleCompanyCodeNom ||
  //     !localMutipleWarehouseCodeNom
  //   ) {
  //     // search all if there are no default search in localstorage
  //     getNoneFilterData()
  //   } else {
  //     // if there are previous search condition in cache, search by them
  //     const filters = {
  //       user_id: localStorage.getItem(Define.USER_ID) || '',
  //       user_role: localStorage.getItem(Define.ROLE) || '',
  //     }
  //     dispatch(
  //       getClients({
  //         ...filters,
  //         company_id: localCompany,
  //         warehouse_id: localWarehouse,
  //         company_code_nom: localCompanyNom,
  //         warehouse_code_nom: localWarehouseNom,
  //       })
  //     )
  //     dispatch(
  //       getCompanies({
  //         ...filters,
  //         client_id: localClient,
  //         warehouse_id: localWarehouse,
  //         client_code_nom: localClientCodenom,
  //         warehouse_code_nom: localWarehouseNom,
  //       })
  //     )
  //     dispatch(
  //       getWarehouses({
  //         ...filters,
  //         client_id: localClient,
  //         company_id: localCompany,
  //         client_code_nom: localClientCodenom,
  //         company_code_nom: localCompanyNom,
  //       })
  //     )
  //     dispatch(
  //       chooseClient({
  //         client: localClient,
  //         company: localCompany,
  //         warehouse: localWarehouse,
  //       })
  //     )

  //     setTimeout(() => {
  //       const parseClient = JSON.parse(localMutipleClientCodeNom || '[]')
  //       const parseCompany = JSON.parse(localMutipleCompanyCodeNom || '[]')
  //       const parseWarehouse = JSON.parse(localMutipleWarehouseCodeNom || '[]')
  //       form.setFieldsValue({
  //         client: parseClient,
  //         company: parseCompany,
  //         warehouse: parseWarehouse,
  //       })
  //       const client = parseClient.map(({ label }: any) => label)
  //       const company = parseCompany.map(({ label }: any) => label)
  //       const warehouse = parseWarehouse.map(({ label }: any) => label)
  //       const params = mapParamFilter({
  //         company_code_nom: company,
  //         client_code_nom: client,
  //         warehouse_code_nom: warehouse,
  //       })

  //       //fetch corresponding ref and member select when client, warehouse, company are filled
  //       dashboardApi
  //         .dashboardFilterRefMember(params)
  //         .then((res) => {
  //           const members = res.data.members?.map((item) => {
  //             return { label: item.nom, value: item.id }
  //           })
  //           setMemberOptions(members)
  //           const refs = res.data.references?.map((item) => {
  //             return { label: item.nom, value: item.id }
  //           })
  //           setRefOptions(refs)
  //         })
  //         .catch((e) => {
  //           console.log(e)
  //         })
  //     }, 500)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  useEffect(() => {
    // fetch data when first time mount
    const localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
    const localClientCodenom =
      localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
    const localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
    const localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
    const localCompanyNom =
      localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
    const localWarehouseNom =
      localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
    if (!localClient || !localCompany || !localWarehouse) {
      // search all if there are no default search in localstorage
      getNoneFilterData()
    } else {
      // if there are previous search condition in cache, search by them
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }
      dispatch(
        getClients({
          ...filters,
          company_id: localCompany,
          warehouse_id: localWarehouse,
          company_code_nom: localCompanyNom,
          warehouse_code_nom: localWarehouseNom,
        })
      )
      dispatch(
        getCompanies({
          ...filters,
          client_id: localClient,
          warehouse_id: localWarehouse,
          client_code_nom: localClientCodenom,
          warehouse_code_nom: localWarehouseNom,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          client_id: localClient,
          company_id: localCompany,
          client_code_nom: localClientCodenom,
          company_code_nom: localCompanyNom,
        })
      )
      dispatch(
        chooseClient({
          client: localClient,
          company: localCompany,
          warehouse: localWarehouse,
        })
      )
      setTimeout(() => {
        form.setFieldsValue({
          client: localClientCodenom,
          company: localCompanyNom,
          warehouse: localWarehouseNom,
        })
        const payload = {
          client_code_nom: localClientCodenom,
          company_code_nom: localCompanyNom,
          warehouse_code_nom: localWarehouseNom,
        }
        //fetch corresponding ref and member select when client, warehouse, company are filled
        dashboardApi
          .dashboardFilterRefMember(payload)
          .then((res) => {
            const members = res.data.members?.map((item) => {
              return { label: `${item.name} ${item.pre_name}`, value: item.id }
            })
            setMemberOptions(members)
            const refs = res.data.references?.map((item) => {
              return { label: item.name, value: item.id }
            })
            setRefOptions(refs)
          })
          .catch((e) => {
            console.log(e)
          })
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!choosingClient && !choosingCompany && !choosingWarehouse) {
      // clear choosing option if there is no option in dropdowns
      form.resetFields()
    }
  }, [choosingClient, choosingCompany, choosingWarehouse, form])

  const clientOptions =
    clientList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  const companyOptions =
    companyList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  const warehouseOptions =
    warehouseList?.map((item) => {
      return { label: item.code_nom, value: item.id }
    }) || []

  // useEffect(() => {
  //   async function checkFirstData() {
  //     // get all filter option when mount to calculate the number of options
  //     const filters = {
  //       user_id: localStorage.getItem(Define.USER_ID) || '',
  //       user_role: localStorage.getItem(Define.ROLE) || '',
  //     }
  //     const [fullClients, fullCompanies, fullWarehouses] = await Promise.all([
  //       clientWarehouseApi.getFilterClient(filters),
  //       clientWarehouseApi.getFilterCompany(filters),
  //       clientWarehouseApi.getFilterWarehouse(filters),
  //     ])
  //     const clientList = fullClients.data.entry
  //     const companyList = fullCompanies.data.entry
  //     const warehouseList = fullWarehouses.data.entry
  //     if (
  //       clientList?.length === 1 &&
  //       companyList?.length === 1 &&
  //       warehouseList?.length === 1
  //     ) {
  //       // case there is only one option in each dropdown, set them as default
  //       setOnlyOneOption(true)
  //       form.setFieldsValue({
  //         client: clientList[0].id,
  //         company: companyList[0].id,
  //         warehouse: warehouseList[0].id,
  //       })
  //       localStorage.setItem(Define.CHOOSING_CLIENT, clientList[0].id || '')
  //       localStorage.setItem(Define.CHOOSING_COMPANY, companyList[0].id || '')
  //       localStorage.setItem(
  //         Define.CHOOSING_WAREHOUSE,
  //         warehouseList[0].id || ''
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_CLIENT_CODENOM,
  //         clientList[0].code_nom || ''
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_COMPANY_CODENOM,
  //         companyList[0].code_nom || ''
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_WAREHOUSE_CODENOM,
  //         warehouseList[0].code_nom || ''
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_CLIENT_CODENOM_MULTIPLE,
  //         JSON.stringify([clientList[0].code_nom])
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_COMPANY_CODENOM_MULTIPLE,
  //         JSON.stringify([companyList[0].code_nom])
  //       )
  //       localStorage.setItem(
  //         Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE,
  //         JSON.stringify([warehouseList[0].code_nom])
  //       )
  //       dispatch(
  //         chooseClient({
  //           client: clientList[0].id,
  //           company: companyList[0].id,
  //           warehouse: warehouseList[0].id,
  //         })
  //       )
  //       setTimeout(() => {
  //         const condition = Object.fromEntries(
  //           new URLSearchParams(window.location.search)
  //         )
  //         onSearch?.(condition)
  //       }, 100)
  //     }
  //   }
  //   checkFirstData()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  useEffect(() => {
    async function checkFirstData() {
      // get all filter option when mount to calculate the number of options
      const filters = {
        user_id: localStorage.getItem(Define.USER_ID) || '',
        user_role: localStorage.getItem(Define.ROLE) || '',
      }
      const [fullClients, fullCompanies, fullWarehouses] = await Promise.all([
        clientWarehouseApi.getFilterClient(filters),
        clientWarehouseApi.getFilterCompany(filters),
        clientWarehouseApi.getFilterWarehouse(filters),
      ])
      const clientList = fullClients.data.entry
      const companyList = fullCompanies.data.entry
      const warehouseList = fullWarehouses.data.entry
      if (
        clientList?.length === 1 &&
        companyList?.length === 1 &&
        warehouseList?.length === 1
      ) {
        // case there is only one option in each dropdown, set them as default
        setOnlyOneOption(true)
        // form.setFieldsValue({
        //   client: clientList[0].id,
        //   company: companyList[0].id,
        //   warehouse: warehouseList[0].id,
        // })
        localStorage.setItem(Define.CHOOSING_CLIENT, clientList[0].id || '')
        localStorage.setItem(Define.CHOOSING_COMPANY, companyList[0].id || '')
        localStorage.setItem(
          Define.CHOOSING_WAREHOUSE,
          warehouseList[0].id || ''
        )
        localStorage.setItem(
          Define.CHOOSING_CLIENT_CODENOM,
          clientList[0].code_nom || ''
        )
        localStorage.setItem(
          Define.CHOOSING_COMPANY_CODENOM,
          companyList[0].code_nom || ''
        )
        localStorage.setItem(
          Define.CHOOSING_WAREHOUSE_CODENOM,
          warehouseList[0].code_nom || ''
        )
        dispatch(
          chooseClient({
            client: clientList[0].id,
            company: companyList[0].id,
            warehouse: warehouseList[0].id,
          })
        )
        setTimeout(() => {
          const condition = Object.fromEntries(
            new URLSearchParams(window.location.search)
          )
          onSearch?.(condition)
        }, 100)
      }
    }

    checkFirstData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submitForm = () => {
    form.submit()
  }

  // const onFinish = (values: any) => {
  //   let isValid = true
  //   // check if required fields are chosen
  //   // eslint-disable-next-line array-callback-return
  //   requiredToAdd?.some((field) => {
  //     console.log(values[field])

  //     if (values[field].length === 0) {
  //       isValid = false
  //       return true
  //     }
  //   })

  //   if (!isValid) {
  //     return notification.warning({
  //       message: t('filterNotValid'),
  //       placement: 'topRight',
  //       duration: 5,
  //     })
  //   }

  //   const client_id =
  //     values.client?.value ||
  //     clientOptions.find((item) => item.value === values.client)?.value
  //   const client_code_nom =
  //     values.client?.label ||
  //     clientOptions.find((item) => item.value === values.client)?.label
  //   const company_id =
  //     values.company?.value ||
  //     companyOptions.find((item) => item.value === values.company)?.value
  //   const company_code_nom =
  //     values.company?.label ||
  //     companyOptions.find((item) => item.value === values.company)?.label
  //   const warehouse_id =
  //     values.warehouse?.value ||
  //     warehouseOptions.find((item) => item.value === values.warehouse)?.value
  //   const warehouse_code_nom =
  //     values.warehouse?.label ||
  //     warehouseOptions.find((item) => item.value === values.warehouse)?.label

  //   dispatch(
  //     chooseClient({
  //       client: client_id,
  //       company: company_id,
  //       warehouse: warehouse_id,
  //     })
  //   )
  //   // save to localstorage to reuse when refresh or relogin
  //   localStorage.setItem(Define.CHOOSING_CLIENT, client_id || '')
  //   localStorage.setItem(Define.CHOOSING_COMPANY, company_id || '')
  //   localStorage.setItem(Define.CHOOSING_WAREHOUSE, warehouse_id || '')
  //   localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, client_code_nom || '')
  //   localStorage.setItem(
  //     Define.CHOOSING_COMPANY_CODENOM,
  //     company_code_nom || ''
  //   )

  //   localStorage.setItem(
  //     Define.CHOOSING_WAREHOUSE_CODENOM,
  //     warehouse_code_nom || ''
  //   )
  //   localStorage.setItem(
  //     Define.CHOOSING_CLIENT_CODENOM_MULTIPLE,
  //     JSON.stringify(values.client)
  //   )
  //   localStorage.setItem(
  //     Define.CHOOSING_COMPANY_CODENOM_MULTIPLE,
  //     JSON.stringify(values.company)
  //   )
  //   localStorage.setItem(
  //     Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE,
  //     JSON.stringify(values.warehouse)
  //   )
  //   setTimeout(() => {
  //     const filteredObj = Object.fromEntries(
  //       Object.entries(values)
  //         .filter(
  //           ([_, value]) =>
  //             value !== undefined && value !== '' && value !== null
  //         )
  //         // convert data's type which is datetime to seconds
  //         .map(([name, value]) => {
  //           if (typeof value === 'object' || isValidSecond(value as string)) {
  //             if (name === 'start_date') {
  //               // @ts-ignore
  //               const timeValue = dayjs(value).startOf('day').valueOf()
  //               value = Math.round(
  //                 timeValue > 100000000000 ? timeValue / 1000 : timeValue
  //               )
  //             }

  //             if (name === 'end_date') {
  //               // @ts-ignore
  //               const timeValue = dayjs(value).endOf('day').valueOf()
  //               value = Math.round(
  //                 timeValue > 100000000000 ? timeValue / 1000 : timeValue
  //               )
  //             }
  //           }
  //           return [name, typeof value === 'string' ? value.trim() : value]
  //         })
  //     )
  //     onSearch?.(filteredObj)
  //   }, 100)
  // }
  const onFinish = (values: any) => {
    let isValid = true
    // check if required fields are chosen
    // eslint-disable-next-line array-callback-return
    requiredToAdd?.some((field) => {
      if (!values[field]) {
        isValid = false
        return true
      }
    })
    if (!isValid) {
      return notification.warning({
        message: t('filterNotValid'),
        placement: 'topRight',
        duration: 5,
      })
    }

    const client_id =
      values.client?.value ||
      clientOptions.find((item) => item.value === values.client)?.value
    const client_code_nom =
      values.client?.label ||
      clientOptions.find((item) => item.value === values.client)?.label
    const company_id =
      values.company?.value ||
      companyOptions.find((item) => item.value === values.company)?.value
    const company_code_nom =
      values.company?.label ||
      companyOptions.find((item) => item.value === values.company)?.label
    const warehouse_id =
      values.warehouse?.value ||
      warehouseOptions.find((item) => item.value === values.warehouse)?.value
    const warehouse_code_nom =
      values.warehouse?.label ||
      warehouseOptions.find((item) => item.value === values.warehouse)?.label

    dispatch(
      chooseClient({
        client: client_id,
        company: company_id,
        warehouse: warehouse_id,
      })
    )
    // save to localstorage to reuse when refresh or relogin
    localStorage.setItem(Define.CHOOSING_CLIENT, client_id || '')
    localStorage.setItem(Define.CHOOSING_COMPANY, company_id || '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE, warehouse_id || '')
    localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, client_code_nom || '')
    localStorage.setItem(
      Define.CHOOSING_COMPANY_CODENOM,
      company_code_nom || ''
    )
    localStorage.setItem(
      Define.CHOOSING_WAREHOUSE_CODENOM,
      warehouse_code_nom || ''
    )
    setTimeout(() => {
      delete values.client
      delete values.warehouse
      delete values.company
      const filteredObj = Object.fromEntries(
        Object.entries(values)
          .filter(
            ([_, value]) =>
              value !== undefined && value !== '' && value !== null
          )
          // convert data's type which is datetime to seconds
          .map(([name, value]) => {
            if (typeof value === 'object' || isValidSecond(value as string)) {
              if (name === 'start_date') {
                // @ts-ignore
                const timeValue = dayjs(value).startOf('day').valueOf()
                value = Math.round(
                  timeValue > 100000000000 ? timeValue / 1000 : timeValue
                )
              }

              if (name === 'end_date') {
                // @ts-ignore
                const timeValue = dayjs(value).endOf('day').valueOf()
                value = Math.round(
                  timeValue > 100000000000 ? timeValue / 1000 : timeValue
                )
              }
            }
            return [name, typeof value === 'string' ? value.trim() : value]
          })
      )
      onSearch?.(filteredObj)
    }, 100)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  // function resetFilter() {
  //   dispatch(clearClient())
  //   getNoneFilterData()
  //   localStorage.setItem(Define.CHOOSING_CLIENT, '')
  //   localStorage.setItem(Define.CHOOSING_COMPANY, '')
  //   localStorage.setItem(Define.CHOOSING_WAREHOUSE, '')
  //   localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, '')
  //   localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, '')
  //   localStorage.setItem(Define.CHOOSING_WAREHOUSE_CODENOM, '')
  //   localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM_MULTIPLE, '')
  //   localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM_MULTIPLE, '')
  //   localStorage.setItem(Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE, '')
  //   setTimeout(() => {
  //     setTimeout(() => {
  //       form.resetFields()
  //     }, 200)
  //   }, 200)
  // }
  function resetFilter() {
    dispatch(clearClient())
    getNoneFilterData()
    localStorage.setItem(Define.CHOOSING_CLIENT, '')
    localStorage.setItem(Define.CHOOSING_COMPANY, '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE, '')
    localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, '')
    localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, '')
    localStorage.setItem(Define.CHOOSING_WAREHOUSE_CODENOM, '')
    setRefOptions([])
    setMemberOptions([])
    setTimeout(() => {
      setTimeout(() => {
        onSearch?.({})
        form.resetFields()
      }, 200)
    }, 200)
  }

  // fetch max level of each client, warehouse, company to apply to level's filter
  function searchLevel(allValues: any) {
    clientWarehouseApi
      .getLevel(
        allValues.client?.label ||
          localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
        allValues.warehouse?.label ||
          localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM),
        allValues.company?.label ||
          localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM)
      )
      .then((res) => {
        const result = res?.data?.entry as string[]
        let levelLists = []
        if (result?.includes('-1')) {
          levelLists.push(0)
        }
        const max = result?.reduce((a, b) => Math.max(a, Number(b)), 0) || 0
        levelLists = [
          ...levelLists,
          ...Array.from({ length: max || 0 }, (_, i) => i + 1),
        ]
      })
  }

  useEffect(() => {
    searchLevel({})
  }, [])

  // const handleFieldChange = (changedValues: any, allValues: any) => {
  //   const filters = {
  //     user_id: localStorage.getItem(Define.USER_ID) || '',
  //     user_role: localStorage.getItem(Define.ROLE) || '',
  //     client_id: allValues.client?.value,
  //     warehouse_id: allValues.warehouse?.value,
  //     client_code_nom: allValues.client?.label,
  //     warehouse_code_nom: allValues.warehouse?.label,
  //     company_id: allValues.company?.value,
  //     company_code_nom: allValues.company?.label,
  //   }
  //   // when user change client
  //   if ('client' in changedValues) {
  //     dispatch(
  //       getCompanies({
  //         ...filters,
  //         company_id: undefined,
  //         company_code_nom: undefined,
  //       })
  //     )
  //     dispatch(
  //       getWarehouses({
  //         ...filters,
  //         warehouse_id: undefined,
  //         warehouse_code_nom: undefined,
  //       })
  //     )
  //     searchLevel(allValues)
  //   }
  //   //when user change company
  //   if ('company' in changedValues) {
  //     dispatch(
  //       getClients({
  //         ...filters,
  //         client_id: undefined,
  //         client_code_nom: undefined,
  //       })
  //     )
  //     dispatch(
  //       getWarehouses({
  //         ...filters,
  //         warehouse_code_nom: undefined,
  //         warehouse_id: undefined,
  //       })
  //     )
  //     searchLevel(allValues)
  //   }
  //   //when user change warehouse
  //   if ('warehouse' in changedValues) {
  //     dispatch(
  //       getClients({
  //         ...filters,
  //         client_id: undefined,
  //         client_code_nom: undefined,
  //       })
  //     )
  //     dispatch(
  //       getCompanies({
  //         ...filters,
  //         company_id: undefined,
  //         company_code_nom: undefined,
  //       })
  //     )
  //     searchLevel(allValues)
  //   }

  //   // when user change date state or end
  //   if ('start_date' in changedValues) {
  //     setStartDate(changedValues['start_date'])
  //   }

  //   if ('end_date' in changedValues) {
  //     setEndDate(changedValues['end_date'])
  //   }

  //   if (allValues.company && allValues.client && allValues.warehouse) {
  //     const clientLabels = allValues['client'].map(({ label }: any) => label)
  //     const companyLabels = allValues['company'].map(({ label }: any) => label)
  //     const warehouseLabels = allValues['warehouse'].map(
  //       ({ label }: any) => label
  //     )

  //     if (clientLabels && companyLabels && warehouseLabels) {
  //       const params = mapParamFilter({
  //         company_code_nom: companyLabels,
  //         client_code_nom: clientLabels,
  //         warehouse_code_nom: warehouseLabels,
  //       })
  //       //fetch corresponding ref and member select when client, warehouse, company are filled
  //       dashboardApi
  //         .dashboardFilterRefMember(params)
  //         .then((res) => {
  //           const members = res.data.members?.map((item) => {
  //             return { label: item.nom, value: item.id }
  //           })
  //           setMemberOptions(members)
  //           const refs = res.data.references?.map((item) => {
  //             return { label: item.nom, value: item.id }
  //           })
  //           setRefOptions(refs)
  //         })
  //         .catch((e) => {
  //           console.log(e)
  //         })
  //     }
  //   }
  // }
  const handleFieldChange = (changedValues: any, allValues: any) => {
    const filters = {
      user_id: localStorage.getItem(Define.USER_ID) || '',
      user_role: localStorage.getItem(Define.ROLE) || '',
      client_id: allValues.client?.value,
      warehouse_id: allValues.warehouse?.value,
      client_code_nom: allValues.client?.label,
      warehouse_code_nom: allValues.warehouse?.label,
      company_id: allValues.company?.value,
      company_code_nom: allValues.company?.label,
    }
    // when user change client
    if ('client' in changedValues) {
      dispatch(
        getCompanies({
          ...filters,
          company_id: undefined,
          company_code_nom: undefined,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          warehouse_id: undefined,
          warehouse_code_nom: undefined,
        })
      )
      searchLevel(allValues)
    }
    //when user change company
    if ('company' in changedValues) {
      dispatch(
        getClients({
          ...filters,
          client_id: undefined,
          client_code_nom: undefined,
        })
      )
      dispatch(
        getWarehouses({
          ...filters,
          warehouse_code_nom: undefined,
          warehouse_id: undefined,
        })
      )
      searchLevel(allValues)
    }
    //when user change warehouse
    if ('warehouse' in changedValues) {
      dispatch(
        getClients({
          ...filters,
          client_id: undefined,
          client_code_nom: undefined,
        })
      )
      dispatch(
        getCompanies({
          ...filters,
          company_id: undefined,
          company_code_nom: undefined,
        })
      )
      searchLevel(allValues)
    }

    // when user change date state or end
    if ('start_date' in changedValues) {
      setStartDate({
        // @ts-ignore
        startDate: dayjs(changedValues['start_date']).startOf('day'),
      })
    }

    if ('end_date' in changedValues) {
      // @ts-ignore
      setEndDate({ endDate: dayjs(changedValues['end_date']).endOf('day') })
    }

    if (
      'company' in allValues ||
      'client' in allValues ||
      'warehouse' in allValues
    ) {
      if (
        allValues['company']?.label &&
        allValues['client']?.label &&
        allValues['warehouse']?.label
      ) {
        const payload = {
          client_code_nom: allValues['client'].label,
          company_code_nom: allValues['company'].label,
          warehouse_code_nom: allValues['warehouse'].label,
        }

        //fetch corresponding ref and member select when client, warehouse, company are filled
        dashboardApi
          .dashboardFilterRefMember(payload)
          .then((res) => {
            const members = res.data.members?.map((item) => {
              return { label: `${item.name} ${item.pre_name}`, value: item.id }
            })
            setMemberOptions(members)
            const refs = res.data.references?.map((item) => {
              return { label: item.name, value: item.id }
            })
            setRefOptions(refs)
          })
          .catch((e) => {
            console.log(e)
          })
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', function (e) {
      if (e.key === 'Enter') {
        const focusingElement = document.activeElement
        const searchBarElement =
          document.getElementsByClassName('research-bar')?.[0]
        if (searchBarElement && searchBarElement.contains(focusingElement)) {
          form.submit()
        }
      }
    })
    return () => window.removeEventListener('keydown', () => {})
  }, [])

  return (
    <Form
      form={form}
      name="basic"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      onValuesChange={handleFieldChange}
      initialValues={getUrlFilterParams().condition}
    >
      <div className="research-bar relative text-xs">
        <div
          className="bg-[#EEEEEE] mx-5 px-5 py-2 flex flex-row justify-between items-center"
          style={{
            borderBottomRightRadius: 7,
            borderBottomLeftRadius: 7,
          }}
        >
          <div
            className={`grid gap-x-4`}
            style={{ gridTemplateColumns: 'repeat(8, 1fr)' }}
          >
            <Form.Item noStyle label="" name="client" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Client"
                loading={loadingClient}
                options={clientOptions}
                className="dropdown-mandatory"
              />
              {/* <Select
                allowClear
                mode="multiple"
                maxTagCount={'responsive'}
                showSearch
                labelInValue
                loading={loadingClient}
                notFoundContent={<></>}
                options={clientOptions}
                placeholder="Client"
                className="research-bar-dashboard"
              /> */}
            </Form.Item>
            <Form.Item noStyle label="" name="company" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Société"
                loading={loadingCompany}
                options={companyOptions}
                className="dropdown-mandatory"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="warehouse" colon={false}>
              <CustomDropdown
                width="w-full"
                type="default"
                placeholder="Entrepôt"
                loading={loadingWarehouse}
                options={warehouseOptions}
                className="dropdown-mandatory"
              />
            </Form.Item>
            <Form.Item noStyle label="" name="references" colon={false}>
              <Select
                allowClear
                mode="multiple"
                maxTagCount={'responsive'}
                showSearch
                labelInValue
                onChange={() => {}}
                notFoundContent={<>{t('filterNotValid')}</>}
                options={refOptions}
                placeholder="Toutes références"
                className="research-bar-dashboard"
                style={{ width: 160 }}
              />
            </Form.Item>
            <Form.Item noStyle label="" name="members" colon={false}>
              <Select
                allowClear
                mode="multiple"
                maxTagCount={'responsive'}
                showSearch
                labelInValue
                onChange={() => {}}
                notFoundContent={<>{t('filterNotValid')}</>}
                options={membersOptions}
                placeholder="Tous membres"
                className="research-bar-dashboard"
              />
            </Form.Item>
            <Form.Item noStyle name="start_date" colon={false}>
              <CDatePicker
                placement="topLeft"
                className="w-full research-bar-dashboard"
                disabledDate={(cur) => {
                  return cur && endDate ? cur > dayjs(endDate) : cur > dayjs()
                }}
                defaultValue={dayjs().startOf('month')}
              />
            </Form.Item>
            <Form.Item noStyle name="end_date" colon={false}>
              <CDatePicker
                placement="topLeft"
                className="w-full research-bar-dashboard"
                disabledDate={(cur) => {
                  return cur && startDate
                    ? cur < dayjs(startDate)
                    : cur < dayjs().startOf('month')
                }}
                defaultValue={dayjs()}
              />
            </Form.Item>

            <div className="flex flex-row gap-x-2">
              <img
                tabIndex={0}
                id={filterKey['searchBtn']}
                data-previous-id={filterKey['nomInput']}
                data-next-id={filterKey['resetBtn']}
                src={SEARCH_ICON}
                alt="masques"
                className="cursor-pointer"
                onClick={submitForm}
              />

              <img
                src={CLOSE_ICON}
                alt="masques"
                tabIndex={0}
                id={filterKey['resetBtn']}
                data-previous-id={filterKey['searchBtn']}
                className={
                  isOnlyOneOption ? 'bg-gray3 rounded-md' : 'cursor-pointer'
                }
                onClick={() => !isOnlyOneOption && resetFilter()}
              />
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default ResearchBarDashboard
