import { DashboardParams } from './../../../models/Dashboard'

//map params from object to string
export const mapParamFilter = (params: DashboardParams): string => {
  return Object.entries(params)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((v) => `${key}=${v}`).join('&')
      } else {
        if (value) return `${key}=${value}`
      }
    })
    .filter((el) => !!el)
    .join('&')
}
