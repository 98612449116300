import { useTranslation } from 'react-i18next'
import { AttenduList, CommandeList, StockList } from '../../../models/Dashboard'
import '../index.scss'

interface BoardListInterface {
  initData: AttenduList | StockList | CommandeList
  isPinned?: boolean
}

const BoardList = (props: BoardListInterface) => {
  const { initData = {}, isPinned = false } = props
  const { t } = useTranslation()

  return (
    <div className="rounded-xl text-[#808080] py-1 w-1/2 scroll-content board-list">
      {initData &&
        Object.entries(initData).map(([key, value]) => (
          <div
            key={key}
            className={`flex justify-between py-2 truncate font-medium ${
              isPinned ? 'px-4' : 'px-2'
            }`}
          >
            <div>{t(`dashboard.${key}`)}:</div>
            <div>{value}</div>
          </div>
        ))}
    </div>
  )
}

export default BoardList
