import dayjs from 'dayjs'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Define from '../../constants/define'
import {
  mapDataAttenduBoardList,
  mapDataCommandeBoardList,
  mapDataStockBoardList,
} from '../../features/dashboard/func/mapDataBoard'
import { mapParamFilter } from '../../features/dashboard/func/mapParamFilter'
import { ModalName } from '../../features/stock/modal/customModalSlice'
import dashboardApi from '../../http/dashboardApi'
import {
  AttenduList,
  CommandeList,
  StickChartItem,
  StockList,
  TypeOfConditionnement,
} from '../../models'

export interface BoardInfoInterface {
  type?: ModalName
  className?: string
  isPinned?: boolean
}

const useBoardHook = () => {
  const navigate = useNavigate()
  const [boardStyle, setBoardStyle] = useState<BoardInfoInterface[]>([
    {
      type: ModalName.ATTENDU,
      className: 'big-board-styled',
      isPinned: true,
    },
    {
      type: ModalName.STOCK,
      className: 'small-board-above-styled',
      isPinned: false,
    },
    {
      type: ModalName.COMMANDE,
      className: 'small-board-below-styled',
      isPinned: false,
    },
    {
      type: ModalName.HISTORY,
      className: 'small-board-below-styled',
      isPinned: false,
    },
  ])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [stockBoardList, setStockBoardList] = useState<StockList>()
  const [attenduBoardList, setAttenduBoardList] = useState<AttenduList>()
  const [commandeBoardList, setCommandeBoardList] = useState<CommandeList>()
  const [stockPie, setStockPie] = useState<TypeOfConditionnement[]>()
  const [commandePie, setCommandePie] = useState<TypeOfConditionnement[]>()
  const [stockStick, setStockStick] = useState<StickChartItem[]>()
  const [attenduStick, setAttenduStick] = useState<StickChartItem[]>()
  const [commandeStick, setCommandeStick] = useState<StickChartItem[]>()

  //fetch data first time
  useEffect(() => {
    // const clientLocalStorage = localStorage.getItem(
    //   Define.CHOOSING_CLIENT_CODENOM_MULTIPLE
    // )
    // const companyLocalStorage = localStorage.getItem(
    //   Define.CHOOSING_COMPANY_CODENOM_MULTIPLE
    // )
    // const warehouseLocalStorage = localStorage.getItem(
    //   Define.CHOOSING_WAREHOUSE_CODENOM_MULTIPLE
    // )
    // if (clientLocalStorage && companyLocalStorage && warehouseLocalStorage) {
    //   const client = JSON.parse(clientLocalStorage).map(
    //     ({ label }: any) => label
    //   )
    //   const company = JSON.parse(companyLocalStorage).map(
    //     ({ label }: any) => label
    //   )
    //   const warehouse = JSON.parse(warehouseLocalStorage).map(
    //     ({ label }: any) => label
    //   )

    //   const params = mapParamFilter({
    //     company_code_nom: company,
    //     warehouse_code_nom: warehouse,
    //     client_code_nom: client,
    //     start_date: dayjs().startOf('month').unix(),
    //     end_date: dayjs().endOf('day').unix(),
    //   })
    const clientCodeNom =
      localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
    const companyCodeNom =
      localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
    const warehouseCodeNom =
      localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
    if (!!clientCodeNom && !!companyCodeNom && warehouseCodeNom) {
      const params = mapParamFilter({
        company_code_nom: [companyCodeNom],
        warehouse_code_nom: [warehouseCodeNom],
        client_code_nom: [clientCodeNom],
        start_date: dayjs().startOf('month').unix(),
        end_date: dayjs().endOf('day').unix(),
      })

      fetchDataDashboard(params)
    }
  }, [])

  //fetch data when click search icon
  const searchDataDashboard = useCallback((values: any) => {
    // const client = values.client?.map(({ label }: any) => label)
    // const company = values.company?.map(({ label }: any) => label)
    // const warehouse = values.warehouse?.map(({ label }: any) => label)
    // const members = values.members?.map(({ label }: any) => label)
    // const references = values.references?.map(({ label }: any) => label)

    // const params = mapParamFilter({
    //   company_code_nom: company,
    //   warehouse_code_nom: warehouse,
    //   client_code_nom: client,
    //   members,
    //   references,
    //   start_date: values.start_date || dayjs().startOf('month').unix(),
    //   end_date: values.end_date || dayjs().endOf('day').unix(),
    // })

    // fetchDataDashboard(params)
    const members = values.members?.map((el: any) => el.value)
    const references = values.references?.map((el: any) => el.value)

    const clientCodeNom =
      localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
    const companyCodeNom =
      localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
    const warehouseCodeNom =
      localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
    const params = mapParamFilter({
      company_code_nom: [companyCodeNom],
      warehouse_code_nom: [warehouseCodeNom],
      client_code_nom: [clientCodeNom],
      members,
      references,
      start_date: values.start_date || dayjs().startOf('month').unix(),
      end_date: values.end_date || dayjs().endOf('day').unix(),
    })
    fetchDataDashboard(params)
  }, [])

  //fetch data to 3 board
  const fetchDataDashboard = async (payload: string) => {
    setIsLoading(true)

    try {
      const [stockEntry, attenduEntry, commandeEntry] = await Promise.all([
        dashboardApi.dashboardStock(payload),
        dashboardApi.dashboardAttendu(payload),
        dashboardApi.dashboardCommande(payload),
      ])

      setStockBoardList(mapDataStockBoardList(stockEntry.data))
      setAttenduBoardList(mapDataAttenduBoardList(attenduEntry.data))
      setCommandeBoardList(mapDataCommandeBoardList(commandeEntry.data))
      setStockPie(stockEntry.data.type_of_conditionnement)
      setCommandePie(commandeEntry.data.type_of_conditionnement)
      setStockStick(stockEntry.data.stick_chart)
      setAttenduStick(attenduEntry.data.stick_chart)
      setCommandeStick(commandeEntry.data.stick_chart)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }

  //pin current board as the main board
  const pinBoard = (type: ModalName) => {
    const prevPinnedBoard = boardStyle.find((el) => el.isPinned)
    const curPinnedBoard = boardStyle.find((el) => el.type === type)
    const unchangedBoard = boardStyle.find(
      (el) => !el.isPinned && el.type !== type
    )

    if (curPinnedBoard?.className?.includes('small-board-above')) {
      setBoardStyle(([previous]) => {
        return [
          {
            type,
            className: 'big-board-styled small-board-above-to-big-board',
            isPinned: true,
          },
          {
            type: prevPinnedBoard?.type || ModalName.OTHER,
            className:
              previous.type === prevPinnedBoard?.type
                ? `${curPinnedBoard?.className} big-board-to-small-board-above` ||
                  ''
                : unchangedBoard?.className || '',
            isPinned: false,
          },
          {
            type: unchangedBoard?.type || ModalName.OTHER,
            className:
              previous.type === unchangedBoard?.type
                ? curPinnedBoard?.className || ''
                : unchangedBoard?.className || '',
            isPinned: false,
          },
        ]
      })
    }

    if (curPinnedBoard?.className?.includes('small-board-below')) {
      setBoardStyle(([previous]) => {
        return [
          {
            type,
            className: 'big-board-styled small-board-below-to-big-board',
            isPinned: true,
          },
          {
            type: prevPinnedBoard?.type || ModalName.OTHER,
            className:
              previous.type === prevPinnedBoard?.type
                ? `${curPinnedBoard?.className} big-board-to-small-board-below` ||
                  ''
                : unchangedBoard?.className || '',
            isPinned: false,
          },
          {
            type: unchangedBoard?.type || ModalName.OTHER,
            className:
              previous.type === unchangedBoard?.type
                ? curPinnedBoard?.className || ''
                : unchangedBoard?.className || '',
            isPinned: false,
          },
        ]
      })
    }
  }

  //click on board title => navigate to the corresponding page
  const onClickTitle = (type: ModalName) => {
    switch (type) {
      case ModalName.ATTENDU:
        navigate('/gestion-de-stock/attendu?page-index=1&page-size=25')
        break
      case ModalName.COMMANDE:
        navigate('/gestion-de-stock/commande?page-index=1&page-size=25')
        break
      case ModalName.STOCK:
        navigate('/gestion-de-stock/stock')
        break
      case ModalName.HISTORY:
        navigate('/gestion-de-stock/history')
        break
      default:
        break
    }
  }

  return {
    boardStyle,
    pinBoard,
    onClickTitle,
    stockBoardList,
    attenduBoardList,
    commandeBoardList,
    stockPie,
    commandePie,
    stockStick,
    attenduStick,
    commandeStick,
    isLoading,
    searchDataDashboard,
  }
}

export default useBoardHook
