import {
  AttenduDashboardInterface,
  CommandeDashboardInterface,
  DashboardFilterParamInterface,
  DashboardFilterResponseInterface,
  StockDashboardInterface,
} from './../models'
import baseAxios from './clientAPI'

const dashboardApi = {
  dashboardStock(params: string): Promise<StockDashboardInterface> {
    const url = `warehouse/stock/dashboard?${params}`
    return baseAxios.get(url)
  },

  dashboardAttendu(params: string): Promise<AttenduDashboardInterface> {
    const url = `warehouse/attendu/dashboard?${params}`
    return baseAxios.get(url)
  },

  dashboardCommande(params: string): Promise<CommandeDashboardInterface> {
    const url = `warehouse/commande/dashboard?${params}`
    return baseAxios.get(url)
  },

  dashboardFilterRefMember(
    // params: string
    params: DashboardFilterParamInterface
  ): Promise<DashboardFilterResponseInterface> {
    const url = `warehouse/accessories/filter-dashboard`
    return baseAxios.get(url, { params })
  },
}

export default dashboardApi
